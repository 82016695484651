import { Fragment, useCallback, useEffect, useState } from 'react';
import './OffersList.css';
import OfferDetailsDialog from './offerDetailsDialog/OfferDetailsDialog';
import TableFooter from '../../components/table/footer/TableFooter';
import { useStateValue } from '../../StateProvider';
import Moment from 'react-moment';
import { formatCurrency } from '../../utils/Common';
import { DeleteOffer, UpdateOffersIndexes } from '../../Services';
import { toast } from 'react-toastify';
import DndCard from '../../components/dragAndDrop/DragAndDropCard';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const OffersList = ({ allOffers, onSubmit, onListRefresh, isSystemList = false }) => {
	const [ offers, setOffers ] = useState([]);
	const [ showDialog, setShowDialog ] = useState(false);
	const [ editedOfferId, setEditedOfferId ] = useState(null);
	const [ duplicateOfferId, setDuplicateOfferId ] = useState(null);
	const [ { user, host }, dispatch ] = useStateValue();

	useEffect(
		() => {
			if (allOffers && allOffers.length > 0) {
				setOffers(allOffers);
			}
		},
		[ allOffers ]
	);

	const handleToggleDialog = async (e, id) => {
		if (e) e.preventDefault();

		setShowDialog(!showDialog);

		if (!showDialog) {
			if (id !== undefined) {
				//edit
				setEditedOfferId(id);
			} else {
				//new
				setEditedOfferId(null);
			}
		} else {
			setEditedOfferId(null);
			setDuplicateOfferId(null);
		}
	};

	const handleOfferSubmit = async () => {
		if (onSubmit) {
			onSubmit();
		}

		handleToggleDialog();
	};

	const handleListRefresh = async () => {
		if (onListRefresh) {
			onListRefresh();
		}
	};

	const handleDuplicateOffer = (id, showDialog = false) => {
		setDuplicateOfferId(id);
		setEditedOfferId(null);
		setShowDialog(showDialog);
	};

	const handleDeleteOffer = async (e, id) => {
		e.preventDefault();

		if (window.confirm('Are you sure that you want to delete selected offer?')) {
			let response = await DeleteOffer(id);

			if (response) {
				toast.success('Offer successfully deleted');

				if (onListRefresh) {
					onListRefresh();
				}
			}
		}
	};

	const handleItemMove = useCallback(
		(dragIndex, hoverIndex) => {
			const dragItem = offers[dragIndex];
			const hoverItem = offers[hoverIndex];

			// Swap places of dragItem and hoverItem in the offers array
			setOffers((offers) => {
				const updatedOffers = [ ...offers ];
				updatedOffers[dragIndex] = hoverItem;
				updatedOffers[hoverIndex] = dragItem;

				//update index
				updatedOffers[hoverIndex].orderIndex = hoverIndex;
				updatedOffers[dragIndex].orderIndex = dragIndex;

				UpdateOffersIndexes(updatedOffers);

				return updatedOffers;
			});
		},
		[ offers ]
	);

	return (
		<Fragment>
			<Fragment>
				<div className="new-offer-wrapper">
					<button className="btn btn-primary" onClick={(e) => handleToggleDialog(e, null)}>
						<span>{`Add New ${isSystemList ? 'System ' : ''}Offer`}</span>
					</button>
				</div>
				<table className="items-list table table-responsive offers-list">
					<thead>
						<tr>
							{/* <th className="number" scope="col">
								#
							</th> */}
							<th scope="col" className="offer-name">
								Name
							</th>
							<th scope="col">Id</th>
							{/* <th className="description text-truncate" scope="col">
								Description
							</th> */}
							{user &&
							user.isAdmin &&
							!isSystemList && (
								<th scope="col" className="user-email">
									User
								</th>
							)}
							<th className="count" scope="col">
								Price
							</th>
							{!isSystemList && (
								<th className="count" scope="col">
									Discount
								</th>
							)}
							<th className="count" scope="col">
								Payments
							</th>
							<th className="count" scope="col">
								Locations
							</th>
							<th className="date" scope="col">
								Created
							</th>
							<th className="offer-action" scope="col" />
						</tr>
					</thead>
					<tbody>
						{offers &&
						offers.length > 0 && (
							<Fragment>
								<DndProvider backend={HTML5Backend}>
									{offers.map((offer, index) => {
										let devUrl = host.indexOf('localhost') > -1 || host.indexOf('dev.') > -1;

										return (
											<DndCard
												index={index}
												id={offer.id}
												isTableRow={true}
												key={offer.id}
												moveCard={handleItemMove}
												text={
													<Fragment>
														{/* <th className="number" scope="row">
														{index + 1}
													</th> */}
														{/* <td>{offer.product ? offer.product.name : ''}</td> */}
														{/* <td>{offer.name}</td> */}
														<td className="offer-name text-truncate" title={offer.name}>
															{`${offer.name}${offer.isDefault ? ' (DEFAULT)' : ''}`}
														</td>
														<td>{offer.id}</td>
														{user &&
														user.isAdmin &&
														!isSystemList && (
															<td className="user-email text-truncate">
																{offer.user ? offer.user.email : ''}
															</td>
														)}
														{/* <td className="description text-truncate">{offer.description}</td> */}
														<td className="count">
															{offer.price ? `${formatCurrency(offer.price, true)}` : ''}
														</td>
														{!isSystemList && (
															<td className="count">
																{offer.discount ? (
																	`${formatCurrency(offer.discount, true)}`
																) : (
																	''
																)}
															</td>
														)}
														{/* <td className="count">{offer.trialDays}</td> */}
														<td className="count">
															{offer.offerItems ? offer.offerItems.length : ''}
														</td>
														<td className="count">{offer.numberOfLocations || '1'}</td>
														<td className="date">
															{offer.dateCreated ? (
																<Moment format="MM/DD/YYYY">{offer.dateCreated}</Moment>
															) : (
																''
															)}
														</td>
														<td className="task-action text-end">
															<button
																className="btn btn-link py-0"
																onClick={(e) => handleToggleDialog(e, offer.id)}
																title="Edit Offer Details"
															>
																<i className="fa-solid fa-pen" />
															</button>
															<button
																className="btn btn-link py-0"
																onClick={(e) => handleDuplicateOffer(offer.id, true)}
																title="Duplicate Offer"
															>
																<i className="fa-regular fa-copy" />
															</button>
															<a
																href={`https://${devUrl
																	? 'dev-'
																	: ''}sales.mapboost.com/orders/${offer.id}`}
																target="_blank"
																className="btn btn-link py-0"
																title="Open Offer"
															>
																<i className="fa-solid fa-up-right-from-square" />
															</a>
															<button
																className="btn btn-link py-0"
																onClick={(e) => handleDeleteOffer(e, offer.id)}
																title="Delete Offer"
															>
																<i className="fa-solid fa-trash-can" />
															</button>
														</td>
													</Fragment>
												}
											/>
										);
									})}
								</DndProvider>
							</Fragment>
						)}
					</tbody>
					<TableFooter list={offers} colspan={9} showRefreshButton={true} onListRefresh={handleListRefresh} />
				</table>
			</Fragment>

			{showDialog && (
				<OfferDetailsDialog
					offerId={editedOfferId}
					onSubmit={handleOfferSubmit}
					onClose={handleToggleDialog}
					onDuplicate={handleDuplicateOffer}
					duplicateSourceId={duplicateOfferId}
					isSystem={isSystemList}
				/>
			)}
		</Fragment>
	);
};

export default OffersList;
