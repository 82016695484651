import { useState, useEffect, Fragment } from 'react';
import { useStateValue } from '../../StateProvider';
import './NewReportApiTest.css';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageHeadline from '../../components/pageHeadline/PageHeadline';
import {
	GetAccountBalance,
	GetApiStatus,
	GetBusinessInfoLive,
	GetBusinessInfoTaskPost,
	GetBusinessProfilePosts,
	GetGoogleProfileReviews,
	GetBusinessProfilePhotos,
	GetBusinessProfileReviews,
	GetBusinessProfileDetails,
	GetBusinessProfileQA,
	GetOnPageSEO,
	GetDSEOTrendsExplore
} from '../../ReportAPI';
import ReactJsonView from '@microlink/react-json-view';
import Select from 'react-select';
import { GetCampaign, GetCampaignsForSelect } from '../../Services';
import { GetAccountData } from '../../ReportAPI';
import { GetBacklinksSummary } from '../../ReportAPI';

const NewReportApiTest = () => {
	const [ isSubmitting, setIsSubmitting ] = useState(false);
	const [ { user, host }, dispatch ] = useStateValue();
	const [ methodTitle, setMethodTitle ] = useState('');
	const [ placeId, setPlaceId ] = useState('');
	const [ campaigns, setCampaigns ] = useState();
	const [ selectedCampaign, setSelectedCampaign ] = useState();
	const [ selectedCampaignData, setSelectedCampaignData ] = useState();
	const [ onPageTarget, setOnPageTarget ] = useState('');
	const [ onPageStartUrl, setOnPageStartUrl ] = useState('');
	const [ onPageMaxCrawlPages, setOnPageMaxCrawlPages ] = useState('');
	const [ apiResponse, setApiResponse ] = useState('');
	const [ selectedApiMethod, setSelectedApiMethod ] = useState();
	const dataForSeoApiMethods = [
		{
			value                  : 'GetBusinessProfileInfoStandard',
			label                  : 'Get Business Profile Info (Standard)',
			showCampaign           : true,
			showBusinessDataParams : true
		},
		{
			value                  : 'GetBusinessProfileInfoLive',
			label                  : 'Get Business Profile Info (Live)',
			showCampaign           : true,
			showBusinessDataParams : true
		},
		{
			value                  : 'GetBusinessProfilePosts',
			label                  : 'Get Business Profile Posts',
			showCampaign           : true,
			showBusinessDataParams : true
		},
		{
			value                  : 'GetBusinessProfileQA',
			label                  : 'Get Business Profile Questions & Answers',
			showCampaign           : true,
			showBusinessDataParams : true
		},
		{
			value        : 'GetBacklinksSummary',
			label        : 'Get Backlinks Summary',
			showCampaign : true
			// showBacklinksParams : true
		},
		{
			value        : 'GetDSEOTrendsExplore',
			label        : 'Get DataForSEO Trends Explore',
			showCampaign : true
			// showBacklinksParams : true
		},
		{ value: 'GetOnPageSEO', label: 'Get Page SEO Results', showCampaign: true, showDSEOOnPageParams: true },
		{ value: 'GetApiStatus', label: 'Get API Status', showBusinessDataParams: false },
		{ value: 'GetAccountBalance', label: 'Get Balance', showBusinessDataParams: false }
	];
	const serpApiMethods = [
		{
			value                  : 'GetBusinessProfileDetails',
			label                  : 'Get Business Profile Details',
			showCampaign           : true,
			showBusinessDataParams : true
		},
		{
			value                  : 'GetBusinessProfileReviews',
			label                  : 'Get Business Profile Reviews',
			showCampaign           : true,
			showBusinessDataParams : true
		},
		{
			value                  : 'GetBusinessProfilePhotos',
			label                  : 'Get Business Profile Photos',
			showCampaign           : true,
			showBusinessDataParams : true
		},
		{
			value : 'GetAccountData',
			label : 'Get Account Data'
		}
	];
	const emptySelectItem = {
		value : null,
		label : ''
	};
	const emptyApiResponse = {
		response : 'No items found'
	};

	useEffect(() => {
		getCampaigns();
	}, []);

	const getCampaigns = async () => {
		let response = await GetCampaignsForSelect();

		if (response && response.length) {
			//let tempCampaigns = response.filter((x) => x.status === 'Active');
			let tempCampaigns = response;
			let tempCampaignsForSelect = [];
			tempCampaignsForSelect.push(emptySelectItem);

			//sort alphabetically
			tempCampaigns = tempCampaigns.sort((a, b) => a.name.localeCompare(b.name));

			for (let i = 0; i < tempCampaigns.length; i++) {
				tempCampaignsForSelect.push({
					value : tempCampaigns[i].id,
					label : `${tempCampaigns[i].name}${tempCampaigns[i].nameExtension
						? ` - ${tempCampaigns[i].nameExtension}`
						: ''}`
				});
			}

			setCampaigns(tempCampaignsForSelect);
		}
	};

	const getCampaignDetails = async (id) => {
		let response = await GetCampaign(id);

		if (response) {
			setSelectedCampaignData(response);
		}
	};

	const handleSubmit = async () => {
		if (selectedApiMethod) {
			handleSelectedApiMethodCall();
		} else {
			toast.error('API method not selected');
		}
	};

	const handleSelectedApiMethodCall = async () => {
		if (selectedApiMethod.showCampaign) {
			if (!selectedCampaignData || !selectedCampaignData.id) {
				toast.error('Campaign is required field');
				return;
			}
		}

		if (selectedApiMethod.showDSEOOnPageParams) {
			if (!onPageTarget || onPageTarget.trim() === '') {
				toast.error('Target domain is required field');
				return;
			}

			if (!onPageMaxCrawlPages || onPageMaxCrawlPages.trim() === '') {
				toast.error('Max crawl pages is required field');
				return;
			}
		}

		setIsSubmitting(true);

		let data;
		let response;

		switch (selectedApiMethod.value) {
			case 'GetBusinessProfileInfoStandard':
				data = {
					placeId : selectedCampaignData.googlePlaceId,
					lat     : selectedCampaignData.lat,
					lng     : selectedCampaignData.lng,
					zoom    : 13
				};
				response = await GetBusinessInfoTaskPost(data);
				break;
			case 'GetBusinessProfileInfoLive':
				data = {
					placeId : selectedCampaignData.googlePlaceId,
					lat     : selectedCampaignData.lat,
					lng     : selectedCampaignData.lng,
					zoom    : 13
				};
				response = await GetBusinessInfoLive(data);
				break;
			case 'GetBusinessProfilePosts':
				data = {
					placeId : selectedCampaignData.googlePlaceId,
					lat     : selectedCampaignData.lat,
					lng     : selectedCampaignData.lng,
					zoom    : 13
				};
				response = await GetBusinessProfilePosts(data);
				break;
			case 'GetBusinessProfileQA':
				data = {
					placeId : selectedCampaignData.googlePlaceId,
					lat     : selectedCampaignData.lat,
					lng     : selectedCampaignData.lng,
					zoom    : 13
				};
				response = await GetBusinessProfileQA(data);
				break;
			case 'GetOnPageSEO':
				data = { target: onPageTarget, startUrl: onPageStartUrl };
				response = await GetOnPageSEO(data);
				break;
			case 'GetBacklinksSummary':
				if (!selectedCampaignData.googleBusinessUrl || selectedCampaignData.googleBusinessUrl.trim() === '') {
					toast.error('Campaign business url is empty');
					setIsSubmitting(false);
					return;
				}

				data = { target: selectedCampaignData.googleBusinessUrl };
				response = await GetBacklinksSummary(data);
				break;
			case 'GetDSEOTrendsExplore':
				if (!selectedCampaignData.keywords || selectedCampaignData.keywords.length === 0) {
					toast.error('Campaign keywords not found');
					setIsSubmitting(false);
					return;
				}

				data = { keywords: selectedCampaignData.keywords.map((x) => x.keyword) };
				response = await GetDSEOTrendsExplore(data);
				break;
			case 'GetApiStatus':
				response = await GetApiStatus();
				break;
			case 'GetAccountBalance':
				response = await GetAccountBalance();
				break;
			case 'GetBusinessProfileDetails':
				data = { placeId: selectedCampaignData.googlePlaceId };
				response = await GetBusinessProfileDetails(data);
				break;
			case 'GetBusinessProfileReviews':
				data = { placeId: selectedCampaignData.googlePlaceId };
				response = await GetBusinessProfileReviews(data);
				break;
			case 'GetBusinessProfilePhotos':
				data = { placeId: selectedCampaignData.googlePlaceId };
				response = await GetBusinessProfilePhotos(data);
				break;
			case 'GetAccountData':
				response = await GetAccountData();
				break;
		}

		if (!response || !response.success || !response.data) {
			// setApiResponse('ERROR: ' + response.message || 'ERROR: Could not get API response');

			let error = response.message || 'ERROR: Could not get API response';
			setApiResponse({ ERROR: error });
		} else {
			if (Array.isArray(response.data) && response.data.length === 0) {
				setApiResponse(emptyApiResponse);
			} else {
				setApiResponse(response.data);
			}
		}

		setIsSubmitting(false);
	};

	return (
		<div className="app-container">
			<div className="grid-tool-left-container">
				<div className="m-3">
					<h4 className="mb-3 py-2 border-bottom">API Methods</h4>
					<div className="accordion" id="accordionDataForSeo">
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className="accordion-button"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#accordionItemDataForSeo"
									aria-expanded="true"
									aria-controls="accordionItemDataForSeo"
								>
									DataForSEO API
								</button>
							</h2>
							<div
								id="accordionItemDataForSeo"
								className="accordion-collapse collapse show"
								data-bs-parent="#accordionDataForSeo"
							>
								<div className="accordion-body">
									{dataForSeoApiMethods.map((apiMethod, index) => (
										<button
											key={index}
											className={`btn w-100 text-start btn-api-method ${selectedApiMethod &&
											apiMethod.value === selectedApiMethod.value
												? 'btn-primary'
												: 'btn-link'}`}
											onClick={() => {
												setSelectedApiMethod(apiMethod);
												setApiResponse();
											}}
										>
											{apiMethod.label}
										</button>
									))}
								</div>
							</div>
						</div>
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className="accordion-button"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#accordionItemSerp"
									aria-expanded="false"
									aria-controls="accordionItemSerp"
								>
									Serp API
								</button>
							</h2>
							<div
								id="accordionItemSerp"
								className="accordion-collapse collapse"
								data-bs-parent="#accordionSerp"
							>
								<div className="accordion-body">
									{serpApiMethods.map((apiMethod, index) => (
										<button
											key={index}
											className={`btn w-100 text-start btn-api-method ${selectedApiMethod &&
											apiMethod.value === selectedApiMethod.value
												? 'btn-primary'
												: 'btn-link'}`}
											onClick={() => {
												setSelectedApiMethod(apiMethod);
												setApiResponse();
											}}
										>
											{apiMethod.label}
										</button>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="app-content-container">
				<div className="container app-content-inner-container">
					<div className="row">
						<div className="col-12">
							<PageHeadline headline={methodTitle} />
						</div>
					</div>
					<div className="main-content-container mt-0">
						<div className="main-content-inner-container">
							{!selectedApiMethod && (
								<div className="row p-3">
									<div className="col-12">Select API method</div>
								</div>
							)}

							{selectedApiMethod && (
								<form className="row p-3">
									<h5 className="mb-4">{selectedApiMethod.label}</h5>

									{selectedApiMethod.showCampaign && (
										<Fragment>
											<label htmlFor="campaigns" className="form-label col-12">
												Campaign:
											</label>
											<div className="col-12 mb-3">
												{campaigns && (
													<Select
														options={campaigns}
														value={selectedCampaign}
														onChange={async (e) => {
															setSelectedCampaign(e);
															await getCampaignDetails(e.value);
														}}
														classNamePrefix="campaigns_select"
														//isClearable={true}
														required
													/>
												)}
											</div>
										</Fragment>
									)}

									{selectedApiMethod.showBusinessDataParams && (
										<Fragment>
											{/* <label htmlFor="placeId" className="form-label col-12">
											Place Id:
										</label>
										<div className="col-12 mb-3">
											<input
												type="text"
												className="form-control"
												name="placeId"
												value={placeId}
												onChange={(e) => setPlaceId(e.target.value)}
												disabled={isSubmitting}
											/>
										</div>
										<label htmlFor="locationName" className="form-label col-12">
											Location Name:
										</label>
										<div className="col-12 mb-3">
											{locations && (
												<Select
													options={locations}
													value={selectedLocation}
													onChange={(e) => {
														setSelectedLocation(e);
													}}
													classNamePrefix="locations_select"
													//isClearable={true}
													required
												/>
											)}
										</div> */}
										</Fragment>
									)}

									{selectedApiMethod.showDSEOOnPageParams && (
										<Fragment>
											<label htmlFor="onPageTarget" className="form-label col-12">
												Target Domain:
											</label>
											<div className="col-12 mb-3">
												<input
													type="text"
													className="form-control"
													name="onPageTarget"
													value={onPageTarget}
													onChange={(e) => setOnPageTarget(e.target.value)}
													disabled={isSubmitting}
													placeholder="Domain name without https:// and www."
													required
												/>
											</div>
											<label htmlFor="onPageMaxCrawlPages" className="form-label col-12">
												Max Crawl Pages:
											</label>
											<div className="col-12 mb-3">
												<input
													type="number"
													className="form-control"
													name="onPageMaxCrawlPages"
													value={onPageMaxCrawlPages}
													onChange={(e) => setOnPageMaxCrawlPages(e.target.value)}
													disabled={isSubmitting}
													min={1}
													max={20}
													placeholder="Number of pages to crawl"
													required
												/>
											</div>
											<label htmlFor="onPageStartUrl" className="form-label col-12">
												Start Url:
											</label>
											<div className="col-12 mb-3">
												<input
													type="text"
													className="form-control"
													name="onPageStartUrl"
													value={onPageStartUrl}
													onChange={(e) => setOnPageStartUrl(e.target.value)}
													disabled={isSubmitting}
													placeholder="First url to crawl (absolute url)"
												/>
											</div>
										</Fragment>
									)}

									<div className="col-12 mb-3">
										<button className="btn btn-primary" type="button" onClick={handleSubmit}>
											{isSubmitting ? (
												<Fragment>
													<span className="spinner-border m-0 me-2" />
													<span>Submitting...</span>
												</Fragment>
											) : (
												<span>Submit</span>
											)}
										</button>
									</div>
									<div className="col-12 mb-2">
										<hr className="col-12 my-2" />
									</div>
									<label htmlFor="apiResponse" className="form-label col-12">
										Response:
									</label>
									<div className="col-12 mb-3">
										<div className="json-viewer form-control">
											<ReactJsonView
												src={apiResponse || {}}
												name={false}
												collapsed={1}
												displayDataTypes={false}
												//onAdd={false}
											/>
										</div>
									</div>
								</form>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NewReportApiTest;
